import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { FinancialOrganizationQuestionnaire } from "../questionnaires/financial-organization-questionnaire";

export function FinancialOrganizationDialog({
  open,
  setOpen,
  questionnaire,
  handleCompleteQuestionnaire,
}) {
  return (
    <Dialog open={open} onOpenChange={() => setOpen(false)}>
      <DialogContent className="sm:max-w-[600px] max-h-full">
        <DialogHeader>
          <DialogTitle className="sr-only">
            Organize Money Experience
          </DialogTitle>
          <DialogDescription className="sr-only">
            Walk through our simple steps to organize your money.
          </DialogDescription>
        </DialogHeader>

        <FinancialOrganizationQuestionnaire
          questionnaire={questionnaire}
          handleCompleteQuestionnaire={handleCompleteQuestionnaire}
        />
      </DialogContent>
    </Dialog>
  );
}
