import { cn } from "@/lib/utils";
export function YesButton({ selected, onClick }) {
  return (
    <button
      onClick={onClick}
      className={cn(
        "w-[150px] flex flex-col items-center rounded-xl border bg-card p-4 text-card-foreground shadow transition-colors hover:bg-gray-200/50",
        selected && "bg-gray-200"
      )}
    >
      <img src="/emoji/thumbs-up.png" alt="Thumbs Up" className="h-10 w-10" />
      <span className="font-medium mt-2">Yes</span>
    </button>
  );
}

export function NoButton({ selected, onClick }) {
  return (
    <button
      onClick={onClick}
      className={cn(
        "w-[150px] flex flex-col items-center rounded-xl border bg-card p-4 text-card-foreground shadow transition-colors hover:bg-gray-200/50",
        selected && "bg-gray-200"
      )}
    >
      <img
        src="/emoji/thumbs-down.png"
        alt="Thumbs Down"
        className="h-10 w-10"
      />
      <span className="font-medium mt-2">No</span>
    </button>
  );
}

export function IDontKnowButton({ selected, onClick }) {
  return (
    <button
      onClick={onClick}
      className={cn(
        "w-[150px] flex flex-col items-center rounded-xl border bg-card p-4 text-card-foreground shadow transition-colors hover:bg-gray-200/50",
        selected && "bg-gray-200"
      )}
    >
      <img
        src="/emoji/question-mark.png"
        alt="Thumbs Down"
        className="h-10 w-10"
      />
      <span className="font-medium mt-2">I don't know</span>
    </button>
  );
}
