"use client";

import axios from "@/axios";
import mixpanel from "mixpanel-browser";
import Bugsnag from "@bugsnag/js";
import { Skeleton } from "@/components/ui/skeleton";
import { useRouter } from "next/navigation";

import Intercom from "@intercom/messenger-js-sdk";

import { createContext, useContext, useEffect, useMemo, useState } from "react";

export function validateToken() {
  return axios
    .get(`${process.env.NEXT_PUBLIC_REACT_APP_API}/auth/validate_token`)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
}

const UserContext = createContext();

export const UserProvider = ({ children, ...props }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const [error, setError] = useState();

  const router = useRouter();

  const navigate = (route) => {
    router.push(route);
  };

  useEffect(() => {
    (async function () {
      try {
        const res = await validateToken();
        setUser(res.data);

        setLoading(false);

        if (process.env.NODE_ENV === "production") {
          mixpanel.identify(res.data.id);
          mixpanel.people.set({ $name: res.data.name, $email: res.data.email });
          Bugsnag.setUser(res.data.id, res.data.email, res.data.name);
        }
      } catch (error) {
        console.log(error);
        setError(error);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (user && user.requires_2fa) {
      if (user.phone_number) {
        navigate(`/otp?phone_number=${user.phone_number}`);
      } else {
        navigate("/otp");
      }
    }
  }, [user]);

  useEffect(() => {
    if (error) {
      navigate("/login");
    }
  }, [error, navigate]);

  function logout() {
    window.localStorage.removeItem("access-token");
    window.localStorage.removeItem("client");
    window.localStorage.removeItem("uid");
    navigate("/login");
  }

  function updatePhoto(formData) {
    return axios
      .put(
        `${process.env.NEXT_PUBLIC_REACT_APP_API}/users/${user.id}`,
        formData
      )
      .then((res) => {
        setUser(res.data);
        return res;
      });
  }

  function updateUser({
    first_name,
    last_name,
    email,
    avatar,
    active_flock_id,
    onboarding_step,
    onboarding_status,
    date_of_birth,
  }) {
    const userParams = {
      first_name,
      last_name,
      email,
      avatar,
      active_flock_id,
      onboarding_step,
      onboarding_status,
      date_of_birth,
    };

    let cleanedUserParams = Object.fromEntries(
      Object.entries(userParams).filter(([_, v]) => v != null)
    );

    return axios
      .put(
        `${process.env.NEXT_PUBLIC_REACT_APP_API}/users/${user.id}`,
        cleanedUserParams
      )
      .then((res) => {
        setUser(res.data);
        return res;
      });
  }

  const memoizedValue = useMemo(() => {
    return {
      user,
      loading,
      setUser,
      logout,
      updateUser,
      updatePhoto,
    };
  }, [loading, user]);

  if (loading) {
    return <Skeleton className="h-4 w-[250px]" />;
  }

  if (error) {
    return;
  }

  if (user.requires_2fa) {
    return <div></div>;
  }

  Intercom({
    app_id: "bsy8itni",
    user_id: user.id,
    name: user.name,
    email: user.email,
    created_at: user.created_at,
    alignment: "left",
  });

  return (
    <UserContext.Provider value={memoizedValue} {...props}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error(
      'The "useUser" hook can only be used within a "UserProvider".'
    );
  }
  return context;
};
