import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import AnimatedCircularProgressBar from "@/components/ui/animated-circular-progress-bar";

import { IDontKnowButton, NoButton, YesButton } from "./utility";
import { BadgeInfo, Lightbulb, Minus, Plus } from "lucide-react";

export function FinancialOrganizationQuestionnaire({
  questionnaire,
  handleCompleteQuestionnaire,
}) {
  const [questionAnswers, setQuestionAnswers] = useState(
    questionnaire?.questions || []
  );
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [progress, setProgress] = useState(0);

  const progressIncrement = 100 / questionAnswers.length;

  useEffect(() => {
    setProgress(
      questionAnswers.filter((q) => q.answer !== null).length *
        progressIncrement
    );
  }, [questionAnswers]);

  useEffect(() => {
    setQuestionAnswers(questionnaire?.questions || []);
  }, [questionnaire]);

  return (
    <>
      <div className="flex flex-col gap-6 sm:gap-12 py-4 items-center">
        <div className="absolute left-4 top-4 text-sm text-muted-foreground flex gap-2 items-center">
          {activeQuestion + 1}/{questionAnswers.length} Questions
          <AnimatedCircularProgressBar
            max={100}
            min={0}
            value={progress}
            gaugePrimaryColor="rgb(79 70 229)"
            gaugeSecondaryColor="rgba(0, 0, 0, 0.1)"
            className="size-5"
          />
        </div>
        {questionAnswers[activeQuestion] && (
          <>
            <div className="flex flex-col items-center gap-2 text-center">
              <h2 className="text-2xl font-bold tracking-tight">
                {questionAnswers[activeQuestion].text}
              </h2>
              <p className="text-md text-muted-foreground">
                {questionAnswers[activeQuestion].subtext}
              </p>
            </div>
            <div className="flex gap-4 flex-row flex-wrap justify-center">
              <YesButton
                selected={questionAnswers[activeQuestion].answer === "yes"}
                onClick={() => {
                  setQuestionAnswers(
                    questionAnswers.map((question, index) =>
                      index === activeQuestion
                        ? { ...question, answer: "yes" }
                        : question
                    )
                  );
                }}
              />
              <NoButton
                selected={questionAnswers[activeQuestion].answer === "no"}
                onClick={() => {
                  setQuestionAnswers(
                    questionAnswers.map((question, index) =>
                      index === activeQuestion
                        ? { ...question, answer: "no" }
                        : question
                    )
                  );
                }}
              />
              <IDontKnowButton
                selected={questionAnswers[activeQuestion].answer === "unknown"}
                onClick={() => {
                  setQuestionAnswers(
                    questionAnswers.map((question, index) =>
                      index === activeQuestion
                        ? { ...question, answer: "unknown" }
                        : question
                    )
                  );
                }}
              />
            </div>
            {questionAnswers[activeQuestion].answer === "unknown" && (
              <div className="w-full max-w-[482px] flex flex-col gap-4">
                <Alert className="shadow-none bg-slate-200">
                  <BadgeInfo className="h-4 w-4" />
                  <AlertTitle>No big deal!</AlertTitle>
                  <AlertDescription>
                    We will make a note and help you along the way to identify
                    any missing information.
                  </AlertDescription>
                </Alert>
              </div>
            )}
            {renderAnswerContext(questionAnswers[activeQuestion]) && (
              <div className="w-full max-w-[482px] flex flex-col gap-4">
                <Alert className="shadow-none bg-muted/30">
                  <Lightbulb className="h-4 w-4 " />
                  <AlertTitle>Don't Worry!</AlertTitle>
                  <AlertDescription>
                    These text boxes are just to help prompt you later as you
                    are completing your action plan. They don't have to be
                    perfect.
                  </AlertDescription>
                </Alert>
                <div className="w-full flex justify-between items-end">
                  <span className="font-medium text-sm">
                    {renderAnswerContextLabel(
                      questionAnswers[activeQuestion].intent
                    )}
                  </span>
                </div>
                {questionAnswers[activeQuestion].answer_context.map(
                  (context, i) => (
                    <div key={i} className="flex w-full gap-2">
                      <Input
                        onChange={(e) => {
                          setQuestionAnswers(
                            questionAnswers.map((question, index) =>
                              index === activeQuestion
                                ? {
                                    ...question,
                                    answer_context: question.answer_context.map(
                                      (ctx, idx) =>
                                        idx === i ? e.target.value : ctx
                                    ),
                                  }
                                : question
                            )
                          );
                        }}
                        value={context}
                        placeholder="What is it?"
                      />

                      <Button
                        onClick={() => {
                          setQuestionAnswers(
                            questionAnswers.map((question, index) =>
                              index === activeQuestion
                                ? {
                                    ...question,
                                    answer_context:
                                      question.answer_context.filter(
                                        (_, idx) => idx !== i
                                      ),
                                  }
                                : question
                            )
                          );
                        }}
                        disabled={i === 0}
                        size="icon"
                        variant="ghost"
                      >
                        <Minus className="w-4 h-4" />
                      </Button>
                    </div>
                  )
                )}
                <Button
                  onClick={() => {
                    setQuestionAnswers(
                      questionAnswers.map((question, index) =>
                        index === activeQuestion
                          ? {
                              ...question,
                              answer_context: [...question.answer_context, ""],
                            }
                          : question
                      )
                    );
                  }}
                  variant="outline"
                  className="rounded-full"
                >
                  <Plus className="h-4 w-4 mr-2" />
                  Add another
                </Button>
              </div>
            )}
          </>
        )}
        <div className="w-full justify-between flex">
          <Button
            onClick={() => setActiveQuestion(activeQuestion - 1)}
            disabled={activeQuestion === 0}
            variant="ghost"
          >
            Back
          </Button>
          {activeQuestion !== questionAnswers.length - 1 && (
            <Button
              onClick={() => setActiveQuestion(activeQuestion + 1)}
              variant="ghost"
            >
              Next
            </Button>
          )}

          {activeQuestion === questionAnswers.length - 1 && (
            <Button onClick={() => setConfirmationOpen(true)} variant="ghost">
              Create My Action Plan
            </Button>
          )}
        </div>
      </div>
      <AlertDialog
        open={confirmationOpen}
        onOpenChange={() => setConfirmationOpen(false)}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you finished?</AlertDialogTitle>
            <AlertDialogDescription>
              You answered{" "}
              {questionAnswers.filter((q) => q.answer !== null).length} out of{" "}
              {questionAnswers.length} questions. Click cancel to go back and
              change or answer any questions. Click confirm to create your
              action plan.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                handleCompleteQuestionnaire(questionAnswers);
                setConfirmationOpen(false);
              }}
            >
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

function renderAnswerContextLabel(intent) {
  switch (intent) {
    case "retirement_account":
      return "Retirement Accounts";
    case "depository_account":
      return "Bank Accounts";
    case "investment_account":
      return "Investment Accounts";
    case "mortgage":
      return "Mortgages";
    case "car_loan":
      return "Car Loans";
    case "credit_card":
      return "Credit Cards";
    case "other_debt":
      return "Other Debt";
    case "life_insurance":
      return "Life Insurance Policies";
    default:
      return "";
  }
}

function renderAnswerContext(questionAnswer) {
  if (questionAnswer.answer === "yes") {
    return true;
  }

  return false;
}
