import { useEffect, useState } from "react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { Checkbox } from "@/components/ui/checkbox";
import { Separator } from "@/components/ui/separator";
import {
  getFinancialAccounts,
  toggleContextState,
  completeAction,
} from "../api";
import { ItemCreationDialog } from "@/app/vault/components/item-creation-dialog";

const intentToHeaderMap = {
  retirement_account: "Add Retirement Accounts",
  depository_account: "Add Depository Accounts",
  investment_account: "Add Investment Accounts",
  credit_card: "Add Credit Cards",
  mortgage: "Add Mortgages",
  life_insurance: "Add Life Insurance",
  car_loan: "Add Car Loans",
  other_debt: "Add Other Debt",
};

const intentToSubheaderMap = {
  retirement_account: "401K, 403B, IRA, Pension, etc",
  depository_account: "Checking, Savings, CDs, etc",
  investment_account: "Brokerage, Annuities, HSA, 529, Mutual Funds, etc",
  credit_card: "",
  mortgage: "Primary or secondary residence or rental property",
  life_insurance: "Term life, whole life, universal life, final expense, etc.",
  car_loan: "",
  other_debt: "Medical loans, student loans, personal loans, etc",
};

const intentToTypeMap = {
  retirement_account: "Investment or Retirement",
  depository_account: "Depository",
  investment_account: "Investment or Retirement",
  credit_card: "Credit Card",
  mortgage: "Loan",
  life_insurance: "insurance",
  car_loan: "Loan",
  other_debt: "Loan",
};

const intentToButtonLabelMap = {
  retirement_account: "retirement",
  depository_account: "depository",
  investment_account: "investment",
  credit_card: "credit card",
  mortgage: "mortgage",
  life_insurance: "insurance",
  car_loan: "car loan",
  other_debt: "other debt",
};

const intentToFilterTypeMap = {
  retirement_account: "investment",
  depository_account: "depository",
  investment_account: "investment",
  credit_card: "credit",
  mortgage: "loan",
  life_insurance: "insurance",
  car_loan: "loan",
  other_debt: "loan",
};

export function FinancialAction({
  actions,
  activeIndex,
  setActiveIndex,
  openPlaid,
  plaidReady,
  setProducts,
  updateAction,
  intent,
}) {
  const [accounts, setAccounts] = useState([]);
  const [reloadCount, setReloadCount] = useState(0);
  const [itemToBeCreated, setItemToBeCreated] = useState();

  function reload() {
    setReloadCount(reloadCount + 1);
  }
  useEffect(() => {
    getFinancialAccounts({ type: intentToFilterTypeMap[intent] }).then(
      (res) => {
        setAccounts(res.data);
      }
    );
  }, [reloadCount, intent]);
  const action = actions.find((action) => action.intent === intent);

  if (!action) {
    return null;
  }
  return (
    <>
      <div className="px-4 flex flex-col gap-2">
        <div className="flex justify-between">
          <h1 className="text-lg font-medium tracking-tight">
            {intentToHeaderMap[intent]}
          </h1>
          <Button
            onClick={() => setItemToBeCreated("financial_account")}
            className="rounded-full"
            variant="outline"
            size="sm"
          >
            <Plus className="mr-2 w-4 h-4" />
            Add Account
          </Button>
        </div>
        <Alert className="bg-muted/50">
          <AlertDescription className="flex gap-2 items-center">
            <p className="text-sm text-muted-foreground">
              {intentToSubheaderMap[intent]}
            </p>
          </AlertDescription>
        </Alert>

        <h2 className="font-heading mt-4 text-lg font-semibold tracking-tight first:mt-0 flex items-center">
          Your Context
        </h2>

        <div className="flex flex-col gap-2">
          {action.context?.map((context) =>
            context.user_context ? (
              <div
                key={context.user_context}
                className="flex gap-2 items-center"
              >
                <Checkbox
                  defaultChecked={context.marked_complete}
                  onClick={() => {
                    toggleContextState({
                      actionId: action.id,
                      context: context.user_context,
                    }).then((res) => updateAction(res.data));
                  }}
                  className="shadow-none"
                />
                <span className="text-sm">{context.user_context}</span>
              </div>
            ) : null
          )}
        </div>

        <h2 className="font-heading mt-4 scroll-m-20 text-lg font-semibold tracking-tight first:mt-0">
          {accounts.length} {intentToTypeMap[intent]} Accounts already added to
          Tapestry
        </h2>
        <div className="flex gap-2 flex-wrap">
          {accounts.map((account) => (
            <div className="rounded-lg border py-1 px-2 flex gap-1 items-center border-gray-200 bg-muted/50">
              <img
                src={
                  account.url
                    ? `https://icon.horse/icon/${extractDomain(account.url)}`
                    : "/images/placeholder.webp"
                }
                className="w-6 h-6 rounded-full"
              />
              <span className="text-foreground text-sm">{account.name}</span>
            </div>
          ))}
        </div>
      </div>
      <Separator className="mt-6" />
      <div className="flex justify-between p-4">
        <Button
          disabled={activeIndex === 0 || action.status === "complete"}
          onClick={() => {
            setActiveIndex(activeIndex + 1);
          }}
          variant="ghost"
        >
          Skip
        </Button>

        {action.status === "complete" ? (
          <Button
            onClick={() => {
              setActiveIndex(activeIndex + 1);
            }}
            variant="ghost"
          >
            Next
          </Button>
        ) : (
          <Button
            onClick={() => {
              completeAction({ actionId: action.id }).then((res) => {
                updateAction(res.data);
                setActiveIndex(activeIndex + 1);
              });
            }}
            variant="ghost"
          >
            All {intentToButtonLabelMap[intent]} accounts added
          </Button>
        )}
      </div>
      <ItemCreationDialog
        reload={reload}
        item={itemToBeCreated}
        open={!!itemToBeCreated}
        onClose={setItemToBeCreated}
        setProducts={setProducts}
        openPlaid={openPlaid}
        plaidReady={plaidReady}
      />
    </>
  );
}

function extractDomain(url) {
  // Ensure the URL has a protocol for the URL constructor
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    url = "http://" + url;
  }

  // Use the URL constructor to parse the URL
  const parsedUrl = new URL(url);

  // Return the hostname
  return parsedUrl.hostname;
}
