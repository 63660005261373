import axios from "@/axios";

export function getVaultPlaybooks() {
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/playbooks?filter=vault`
  );
}

export function completeAction({ actionId }) {
  return axios.put(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/actions/${actionId}/complete_action`
  );
}

export function completePlaybook({ playbookId }) {
  return axios.put(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/playbooks/${playbookId}/complete_playbook`
  );
}

export function createPlaybookAndQuestionnaire({ intent }) {
  return axios.post(`${process.env.NEXT_PUBLIC_REACT_APP_API}/playbooks`, {
    intent,
  });
}

export function completeQuestionnaire({ playbookId, questions }) {
  return axios.post(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/playbooks/${playbookId}/complete_questionnaire`,
    { questions }
  );
}

export function fetchActions(playbookId) {
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/playbooks/${playbookId}/actions`
  );
}

export function getFinancialAccounts({ type }) {
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/financial_accounts?account_type=${type}`
  );
}

export function toggleContextState({ actionId, context }) {
  return axios.put(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/actions/${actionId}/toggle_context_state`,
    { user_context: context }
  );
}
