import { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
} from "@/components/ui/sidebar";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import { ConfettiButton } from "@/components/ui/confetti";
import confetti from "canvas-confetti";

import Image from "next/image";

import { completePlaybook, fetchActions } from "../api";
import { Check } from "lucide-react";
import { FinancialAction } from "../actions/financial-action";
import { AddLifeInsurance } from "../actions/add-life-insurance";
import { Badge } from "@/components/ui/badge";

export function FinancialOrganizationPlaybookDialog({
  open,
  setOpen,
  playbook,
  openPlaid,
  plaidReady,
  setProducts,
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [actions, setActions] = useState([]);

  useEffect(() => {
    if (!open) return;
    fetchActions(playbook.id).then((res) => {
      const groupedActions = groupActionsByIntent(res.data);
      setActions(res.data);
    });
  }, [open, playbook]);

  const menuItems = getFriendlyLabels(actions);

  function updateAction(action) {
    setActions((prevActions) =>
      prevActions.map((a) => (a.id === action.id ? action : a))
    );
  }

  const startFireworks = () => {
    const duration = 5 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    const randomInRange = (min, max) => Math.random() * (max - min) + min;

    const interval = window.setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 50 * (timeLeft / duration);
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        zIndex: 999,
      });
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        zIndex: 999,
      });
    }, 250);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="p-0 max-h-fit md:max-w-[700px] lg:max-w-[800px]">
        <DialogTitle className="sr-only">Organize Money Playbook</DialogTitle>
        <DialogDescription className="sr-only">
          Check off your action plan.
        </DialogDescription>
        <SidebarProvider className="items-start">
          <Sidebar collapsible="none" className="hidden md:flex">
            <SidebarContent>
              <SidebarGroup>
                <SidebarGroupContent>
                  <SidebarMenu>
                    {menuItems.map((item, index) => (
                      <SidebarMenuItem key={item.name}>
                        <SidebarMenuButton
                          asChild
                          onClick={() => {
                            setActiveIndex(index);
                          }}
                          isActive={index === activeIndex}
                        >
                          <div className="flex items-center cursor-pointer">
                            <Image
                              alt="icon"
                              src={`/emoji/${item.icon}.png`}
                              width={20}
                              height={20}
                            />
                            <span>{item.name}</span>
                            {item.complete && <Check className="w-4 h-4" />}
                          </div>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                    ))}
                  </SidebarMenu>
                </SidebarGroupContent>
              </SidebarGroup>
            </SidebarContent>
          </Sidebar>
          <main className="flex h-full flex-1 flex-col overflow-hidden">
            <header className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
              {actions.length > 0 && (
                <div className="flex items-center gap-2 px-4 text-sm text-muted-foreground">
                  {renderCompletionPercentage(actions)}% Complete
                </div>
              )}
            </header>

            {renderCompletionPercentage(actions) === 100 &&
              activeIndex === -1 && (
                <div className="px-4 flex flex-col gap-2">
                  <div className="flex justify-between">
                    <h1 className="text-lg font-medium tracking-tight">
                      🎉 Congratulations!
                    </h1>
                  </div>
                  <p className="text-sm text-muted-foreground">
                    You have successfully completed your actions to organize
                    your money.
                  </p>
                  <Alert className="bg-muted/50">
                    <AlertDescription className="flex gap-2 items-center">
                      <p className="text-sm text-muted-foreground">
                        Don't worry if you realize that you forgot to add
                        something. You can always add additional items in the
                        vault by using the plus button in the upper right hand
                        side of the screen.
                      </p>
                    </AlertDescription>
                  </Alert>
                  <Separator className="mt-6" />
                  <div className="my-4 w-full justify-end flex">
                    <Button
                      onClick={() => {
                        completePlaybook({ playbookId: playbook.id }).then(
                          (res) => {
                            startFireworks();
                            setOpen(false);
                          }
                        );
                      }}
                    >
                      Complete
                    </Button>
                  </div>
                </div>
              )}

            {menuItems[activeIndex]?.intent === "life_insurance" ? (
              <AddLifeInsurance
                intent={menuItems[activeIndex]?.intent}
                actions={actions}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                updateAction={updateAction}
              />
            ) : (
              <FinancialAction
                intent={menuItems[activeIndex]?.intent}
                actions={actions}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                openPlaid={openPlaid}
                plaidReady={plaidReady}
                setProducts={setProducts}
                updateAction={updateAction}
              />
            )}
          </main>
        </SidebarProvider>
      </DialogContent>
    </Dialog>
  );
}

function groupActionsByIntent(actions) {
  return actions.reduce((acc, action) => {
    const { intent } = action;

    // If the intent doesn't exist yet, initialize it with an empty array
    if (!acc[intent]) {
      acc[intent] = [];
    }

    // Push the current action to the appropriate intent array
    acc[intent].push(action);

    return acc;
  }, {});
}

function getFriendlyLabels(actions) {
  const intentToLabelMap = {
    retirement_account: "Retirement Accounts",
    depository_account: "Depository Accounts",
    investment_account: "Investment Accounts",
    credit_card: "Credit Cards",
    mortgage: "Mortgages",
    life_insurance: "Life Insurance",
    car_loan: "Car Loans",
    other_debt: "Other Debt",
  };

  const labels = [
    ...actions.map((action) => {
      return {
        name: intentToLabelMap[action.intent] || "Unknown Intent",
        icon: icons[action.intent],
        intent: action.intent,
        complete: action.status === "complete",
      };
    }),
  ];
  return labels;
}

const icons = {
  introduction: "megaphone",
  retirement_account: "money-bag",
  depository_account: "dollar-banknote",
  investment_account: "money",
  mortgage: "house",
  car_loan: "car",
  credit_card: "credit-card",
  other_debt: "document",
  life_insurance: "umbrella",
};

function renderCompletionPercentage(actions) {
  const totalActions = Object.values(actions).flat().length;
  const completedActions = Object.values(actions)
    .flat()
    .filter((action) => action.status === "complete").length;

  return Math.round((completedActions / totalActions) * 100);
}
