import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Separator } from "@/components/ui/separator";
import { Checkbox } from "@/components/ui/checkbox";
import { ItemCreationDialog } from "@/app/vault/components/item-creation-dialog";
import { Plus } from "lucide-react";

import { toggleContextState, completeAction } from "../api";

export function AddLifeInsurance({
  actions,
  activeIndex,
  setActiveIndex,
  updateAction,
  intent,
}) {
  const [reloadCount, setReloadCount] = useState(0);
  const [itemToBeCreated, setItemToBeCreated] = useState();

  function reload() {
    setReloadCount(reloadCount + 1);
  }

  const action = actions.find((action) => action.intent === intent);

  if (!action) {
    return null;
  }

  return (
    <>
      <div className="px-4 flex flex-col gap-2">
        <div className="flex justify-between">
          <h1 className="text-lg font-medium tracking-tight">
            Add Life Insurance Policies
          </h1>
          <Button
            onClick={() => setItemToBeCreated("document")}
            className="rounded-full"
            variant="outline"
            size="sm"
          >
            <Plus className="mr-2 w-4 h-4" />
            Add Insurance Policy
          </Button>
        </div>
        <Alert className="bg-muted/50">
          <AlertDescription className="flex gap-2 items-center">
            <p className="text-sm text-muted-foreground">
              Term life, whole life, universal life, final expense, etc.
            </p>
          </AlertDescription>
        </Alert>

        <h2 className="font-heading mt-4 text-lg font-semibold tracking-tight first:mt-0 flex items-center">
          Your Context
        </h2>

        <div className="flex flex-col gap-2">
          {action.context?.map((context) => (
            <div key={context.user_context} className="flex gap-2 items-center">
              <Checkbox
                defaultChecked={context.marked_complete}
                onClick={() => {
                  toggleContextState({
                    actionId: action.id,
                    context: context.user_context,
                  }).then((res) => updateAction(res.data));
                }}
                className="shadow-none"
              />
              <span className="text-sm">{context.user_context}</span>
            </div>
          ))}
        </div>
        <Separator className="mt-6" />
        <div className="flex justify-between p-4">
          <Button
            disabled={activeIndex === 0 || action.status === "complete"}
            onClick={() => {
              setActiveIndex(activeIndex + 1);
            }}
            variant="ghost"
          >
            Skip
          </Button>

          {action.status === "complete" ? (
            <Button
              onClick={() => {
                const index = actions.findIndex(
                  (action) => action.status !== "complete"
                );

                setActiveIndex(index);
              }}
              variant="ghost"
            >
              Next
            </Button>
          ) : (
            <Button
              onClick={() => {
                completeAction({ actionId: action.id }).then((res) => {
                  updateAction(res.data);
                  const index = actions.findIndex(
                    (action) => action.status !== "complete"
                  );
                  setActiveIndex(index);
                });
              }}
              variant="ghost"
            >
              All Policies added
            </Button>
          )}
        </div>
      </div>
      <ItemCreationDialog
        reload={reload}
        item={itemToBeCreated}
        open={!!itemToBeCreated}
        onClose={setItemToBeCreated}
      />
    </>
  );
}
