export const VAULT_SEARCH = "vault_search";
export const ROOT_PEOPLE_PAGE = "root_people_page";
export const ROOT_PROPERTY_PAGE = "root_property_page";
export const ROOT_ACCOUNT_PAGE = "root_account_page";
export const ROOT_DOCUMENT_PAGE = "root_document_page";
export const SHARE_DOCUMENT_PORTAL = "share_document_portal";
export const PLANNING = "planning";
export const PROTECT = "protect";
export const PLAID = "plaid";
export const MONEY_LOCATION = "money_location";
export const DASHBOARD = "dashboard";
